import { Draw } from "ol/interaction";

import { measureDrawStyle } from "../../styles/measures";
import { MEASURE_DATA_TYPE } from "../../data-types";
import { calculateMeasureSlopeAdjustedDistance } from "./helpers";
import { LineString } from "ol/geom";

export default class Base {
  constructor(controller) {
    this.controller = controller;

    this.mapManager = controller.mapManager;
    this.map = this.mapManager.map;
    this.measuresVectorSource = this.mapManager.measuresVectorSource;

    this.measureBtnTarget = controller.measureBtnTarget;
  }

  add() {
    if (this.currentMeasureInteraction) return;

    this.constrainPressed = false;
    this.attachKeyboardListeners();

    this.clearCurrentInteraction();

    this.currentMeasureInteraction = new Draw({
      source: this.measuresVectorSource,
      type: "LineString",
      maxPoints: 2,
      style: (feature) => measureDrawStyle(feature, this.map),
      // This is called while drawing the line.  If the user holds the "c" key, the line will be constrained to the
      // horizontal or vertical of the browser window.  Note that after drawing the line becomes points on the map
      // so if the map is rotated the line will rotate with the map and will no longer be horizontal or vertical
      // in the browser window.
      geometryFunction: (coordinates, geometry) => {
        if (!geometry) geometry = new LineString([]);

        if (coordinates.length === 2 && this.constrainPressed) {
          const [start, end] = coordinates;

          const startPixel = this.map.getPixelFromCoordinate(start);
          const endPixel = this.map.getPixelFromCoordinate(end);

          const dx = Math.abs(endPixel[0] - startPixel[0]);
          const dy = Math.abs(endPixel[1] - startPixel[1]);

          if (dx > dy) {
            // Lock to horizontal
            endPixel[1] = startPixel[1];
          } else {
            // Lock to vertical
            endPixel[0] = startPixel[0];
          }

          const adjustedEnd = this.map.getCoordinateFromPixel(endPixel);
          coordinates[1] = adjustedEnd;
        }

        geometry.setCoordinates(coordinates);
        return geometry;
      },
    });

    this.map.addInteraction(this.currentMeasureInteraction);
    this.currentMeasureInteraction.on("drawend", this.onDrawEnd);
    this.controller.snapInteractionManager.refresh();
  }

  attachKeyboardListeners() {
    document.addEventListener("keydown", this.handleKeyDown);
    document.addEventListener("keyup", this.handleKeyUp);
  }

  detachKeyboardListeners() {
    document.removeEventListener("keydown", this.handleKeyDown);
    document.removeEventListener("keyup", this.handleKeyUp);
  }

  handleKeyDown = (event) => {
    if (event.key.toLowerCase() === "c") {
      this.constrainPressed = true;
    }
  };

  handleKeyUp = (event) => {
    if (event.key.toLowerCase() === "c") {
      this.constrainPressed = false;
    }
  };

  remove() {
    if (!this.currentMeasureInteraction) return;

    this.detachKeyboardListeners();
    this.clearCurrentInteraction();
  }

  clearCurrentInteraction() {
    if (this.currentMeasureInteraction) {
      this.map.removeInteraction(this.currentMeasureInteraction);
      delete this.currentMeasureInteraction;
      this.currentMeasureInteraction = undefined;
    }
  }

  onDrawEnd = (event) => {
    const measureFeature = event.feature;

    measureFeature.set("dataType", MEASURE_DATA_TYPE);
    measureFeature.set("slopeAdjustedDistance", calculateMeasureSlopeAdjustedDistance(measureFeature, this.controller));
  };

  finish() {
    if (!this.currentMeasureInteraction) return;

    this.currentMeasureInteraction.finishDrawing();

    const drawingSource = this.mapManager.measuresVectorSource;
    const features = drawingSource.getFeatures();
    const lastFeature = features[features.length - 1];
    drawingSource.removeFeature(lastFeature);
  }
}
