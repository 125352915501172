import { Controller } from "@hotwired/stimulus";
import { addOptionsToSelect } from "../../helpers/form";
import * as animate from "../../helpers/animate";

export default class DaController extends Controller {
  static values = {
    railPlatformRailTypeValues: Object,
    railPlatformChangeRailTypeMapping: Object,
    railTypesFinishes: Object,
    railTypesDefaultFinish: Object,
  };
  static outlets = ["da--rail-lengths"];

  static targets = ["railTypeSelect", "railFinishClear", "railFinishMill", "railFinishBlack"];

  changeRailPlatform(event) {
    const railPlatform = event.currentTarget.value;
    const railTypeValues = this.railPlatformRailTypeValuesValue[railPlatform];
    const currentRailType = this.railTypeSelectTarget.value;
    const newRailType = this.railPlatformChangeRailTypeMappingValue[currentRailType];
    const opts = railTypeValues.map((rt) => [rt, rt, false, newRailType === rt]);
    addOptionsToSelect(opts, this.railTypeSelectTarget);
    this.daRailLengthsOutlet.changeRailType();
    this.updateRailFinishes(newRailType);
  }

  updateRailFinishes(railType) {
    const clearTarget = this.railFinishClearTarget;
    const millTarget = this.railFinishMillTarget;
    const blackTarget = this.railFinishBlackTarget;

    const clearInput = clearTarget.querySelector("input");
    const millInput = millTarget.querySelector("input");
    const blackInput = blackTarget.querySelector("input");

    const availableFinishes = this.railTypesFinishesValue[railType];
    const defaultFinish = this.railTypesDefaultFinishValue[railType];

    const allowsClearFinish = availableFinishes.includes("clear");
    const allowsMillFinish = availableFinishes.includes("mill");
    const allowsBlackFinish = availableFinishes.includes("black");

    const currentFinish = this.#currentFinish(clearInput, millInput, blackInput);
    if (currentFinish === "black") {
      if (!allowsBlackFinish) {
        // If you were using black & you're not allowed to use black with the new rail
        // then switch to the rail's default finish
        this.#setToDefaultFinish(defaultFinish, clearInput, millInput, blackInput);
      }
    } else {
      // If you switch from XR1000 (which is mill only) and we left you in mill, then
      // you'd be getting a custom finish / custom rail which is probably not what you
      // want.
      if (currentFinish !== defaultFinish) {
        this.#setToDefaultFinish(defaultFinish, clearInput, millInput, blackInput);
      }
    }

    this.#showHideFinishOption(allowsClearFinish, clearTarget, clearInput);
    this.#showHideFinishOption(allowsMillFinish, millTarget, millInput);
    this.#showHideFinishOption(allowsBlackFinish, blackTarget, blackInput);
  }

  #currentFinish(clearInput, millInput, blackInput) {
    if (clearInput.checked) return "clear";
    if (millInput.checked) return "mill";
    if (blackInput.checked) return "black";
  }

  #setToDefaultFinish(defaultFinish, clearInput, millInput, blackInput) {
    clearInput.checked = defaultFinish === "clear";
    millInput.checked = defaultFinish === "mill";
    blackInput.checked = defaultFinish === "black";
  }

  #showHideFinishOption(allowed, target, input) {
    if (allowed) {
      animate.show(target);
    } else {
      input.checked = false;
      target.classList.add("d-none");
    }
  }
}
