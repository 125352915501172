import { Controller } from "@hotwired/stimulus";
import { patch } from "@rails/request.js";

import { isVisible } from "../../helpers/app";

// This class create a turbo stream request to repopulate the rail lengths section on the
// project edit and preset pages when the user changes the rail type or rail platform (preset only).

export default class RailLengthsController extends Controller {
  static targets = ["railTypeSelect"];
  static values = { modelName: String, modelCreatedAt: String, railTypeToStockLength: Object };
  static outlets = ["user-presets--da"];

  connect() {
    this.previousRailType = this.railTypeSelectTarget.value;
  }

  changeRailType(_event) {
    this.newRailType = this.railTypeSelectTarget.value;
    this.container = document.querySelector(`#rail_lengths_${this.#railPlatform}`);

    if (this.#shouldRepopulate) {
      const url = "/da/rail_lengths";

      const convertedLengths = this.#convertLengths(this.#availableLengths);

      const formData = new FormData();
      formData.append("rail_platform", this.#railPlatform);
      formData.append("rail_type", this.newRailType);
      formData.append("available_lengths", JSON.stringify(convertedLengths));
      formData.append("rail_cutting", this.#railCutting);
      formData.append("model_name", this.modelNameValue);
      formData.append("model_date", this.modelCreatedAtValue);

      patch(url, {
        body: formData,
        responseKind: "turbo-stream",
      });
    }

    this.previousRailType = this.railTypeSelectTarget.value;
    if (this.hasUserPresetsDaOutlet) {
      this.userPresetsDaOutlet.updateRailFinishes(this.newRailType);
    }
  }

  get #shouldRepopulate() {
    let previousRailPlatform;
    if (this.previousRailType === "A1" || this.previousRailType === "A2") {
      previousRailPlatform = "aire";
    } else {
      previousRailPlatform = "xr";
    }
    const newRailPlatform = this.#railPlatform;

    return (
      previousRailPlatform !== newRailPlatform ||
      ((this.previousRailType === "XR10" || this.previousRailType === "XR100") && this.newRailType === "XR1000") ||
      (this.previousRailType === "XR1000" && (this.newRailType === "XR10" || this.newRailType === "XR100"))
    );
  }

  get #railPlatform() {
    if (this.newRailType === "A1" || this.newRailType === "A2") {
      return "aire";
    } else {
      return "xr";
    }
  }

  #convertLengths(lengths) {
    const oldStock = this.railTypeToStockLengthValue[this.previousRailType];
    const newStock = this.railTypeToStockLengthValue[this.newRailType];

    const converted = [];

    lengths.forEach((length) => {
      const lengthInt = parseInt(length);

      if (oldStock.includes(lengthInt)) {
        const replacement = newStock[oldStock.indexOf(lengthInt)];
        if (!lengths.includes(replacement)) {
          converted.push(replacement);
        }
      } else {
        converted.push(lengthInt);
      }
    });

    return converted;
  }

  get #availableLengths() {
    const inputs = this.container.querySelectorAll(`input[name='${this.modelNameValue}[available_lengths][]']:checked`);

    return [...inputs].filter(isVisible).map((element) => element.value);
  }

  get #railCutting() {
    const railCuttingCheckbox = this.container.querySelector(`#rail_cutting_${this.#railPlatform}`);

    return railCuttingCheckbox.checked;
  }
}
