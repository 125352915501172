import { Snap } from "ol/interaction";

const PIXEL_TOLERANCE = 5;

export default class Base {
  constructor(controller) {
    this.controller = controller;

    this.mapManager = controller.mapManager;
    this.map = this.mapManager.map;

    this.mainSnap = undefined;
    this.secondarySnap = undefined;
    this.tertiarySnap = undefined;
    this.quaternarySnap = undefined;
    this.quinarySnap = undefined;
    this.senarySnap = undefined;
  }

  // The snap interaction must be added after the Modify and Draw interactions
  // in order for its map browser event handlers to be fired first. Its handlers
  // are responsible of doing the snapping.
  add() {
    // Remove the existing interactions, so we can add it again, but after the
    // other interactions have been added
    this.remove();

    if (this.mapManager.measuresVectorSource) {
      this.addSnap("measures", this.mapManager.measuresVectorSource);
    }
    this.addSnap("mainSnap", this.mainTargetSource);
    this.addSnap("secondarySnap", this.secondarySource);
    this.addSnap("tertiarySnap", this.tertiarySource);
    this.addSnap("quaternarySnap", this.quaternarySource);
    this.addSnap("quinarySnap", this.quinarySource);
    this.addSnap("senarySnap", this.senarySource);
  }

  remove() {
    if (this.mapManager.measuresVectorSource) {
      this.removeSnap("measures");
    }
    this.removeSnap("mainSnap");
    this.removeSnap("secondarySnap");
    this.removeSnap("tertiarySnap");
    this.removeSnap("quaternarySnap");
    this.removeSnap("quinarySnap");
    this.removeSnap("senarySnap");
  }

  addSnap(snapName, snapSource) {
    if (snapSource) {
      this[snapName] = new Snap({ source: snapSource, pixelTolerance: PIXEL_TOLERANCE });
      this.map.addInteraction(this[snapName]);
    }
  }

  removeSnap(snapName) {
    const snap = this[snapName];
    if (snap) {
      this.map.removeInteraction(snap);
      this[snapName] = undefined;
    }
  }

  refresh() {
    this.add();
  }
}
